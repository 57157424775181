:root {
  /** brand color tokens */
  --color-primary--rgb-triplet: 39, 52, 139; /* Skanssi blue */
  --color-primary: rgb(var(--color-primary--rgb-triplet, 1));
  --color-secondary--rgb-triplet: 182, 206, 53; /* Skanssi green */
  --color-secondary: rgb(var(--color-secondary--rgb-triplet, 1));
  --color-brand--vanilla-500: rgb(255, 246, 234);
  --color-brand--blue-500: rgb(5, 73, 140);
  --color-brand--blue-100: rgb(188, 221, 253);
  --color-brand--turqoise-200: rgb(168, 218, 224);
  --color-brand--turqoise-100: rgb(233, 246, 247);
  --color-brand--honey-200: rgb(240, 213, 155);
  --color-extra--error: rgb(207, 45, 35);
  --color-extra--error-rgb-triplet: 207, 45, 35;
  --color-extra--attention: rgb(207, 45, 35);
  --color-extra--attention-rgb-triplet: 207, 45, 35;

  --color: rgb(2, 2, 2);
  --color-inactive: rgb(60, 60, 60);
  --color-placeholder: rgb(120, 120, 210);
  --color-bg: rgb(250, 250, 250);
  --color-border: #ced4da; /* rgb(37, 37, 37);*/
  --color-outline: rgb(37, 37, 37);
  --color-shadow: rgb(37, 37, 37);

  --color-text: var(--color);
  --color-text-heading: var(--color-brand--blue-500);

  --color-bg-button: var(--color-primary);
  --color-bg-button--hover: rgb(var(--color-primary--rgb-triplet), 0.9);
  --color-text-button: rgb(254, 253, 250);

  --color-input-label: var(--color-inactive);
  --color-input-placeholder: var(--color-placeholder);

  --color-active--trigger: var(--color);
  --color-inactive--trigger: var(--color-inactive);
  --color-bg-active--trigger: rgb(var(--color-secondary--rgb-triplet), 0.2);
  --color-bg-modal: rgb(var(--color-primary--rgb-triplet), 0.2);

  --color-bg-error: rgb(var(--color-extra--error-rgb-triplet), 0.2);
  --color-bg-warning: var(--color-brand--honey-200);
  --color-bg-success: var(--color-brand--turqoise-100);
  --color-bg-neutral: var(--color-brand--blue-100);

  /* base sizes */
  --size-base: 16px;
  --size-font-base: var(--size-base, 1rem);
  --size-font-sm: calc(var(--size-font-base) * 0.8);
  --size-font-md: var(--size-font-base);
  --size-font-lg: calc(var(--size-font-base) * 1.2);

  --space: 1rem;
  --space-xs: calc(var(--space) / 4);
  --space-sm: calc(var(--space) / 2);
  --space-md: calc(var(--space));
  --space-lg: calc(var(--space) * 2);
  --space-xl: calc(var(--space) * 4);

  --space-form-fieldgap: var(--space-md);

  --radius: 0.25rem;
}
