.reset-button {
  all: unset;
  outline: revert;
  background-color: transparent;
}

.reset-ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
