/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_c0d5fb';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/483de911b1a0d258-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_c0d5fb';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/0e5e1c6a8db9e432-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_c0d5fb';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/5693677ef07d9b51-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_c0d5fb';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/28aa5118b38b86e4-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_c0d5fb';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/8720059dfa14a1fe-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_c0d5fb';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/f1df6186c8d69644-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_c0d5fb';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/120a5a1920781bd0-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Roboto_Fallback_c0d5fb';src: local("Arial");ascent-override: 92.67%;descent-override: 24.39%;line-gap-override: 0.00%;size-adjust: 100.11%
}.__className_c0d5fb {font-family: '__Roboto_c0d5fb', '__Roboto_Fallback_c0d5fb';font-weight: 400;font-style: normal
}

.Toast_viewport__7R6Sn {
  --viewport-padding: 25px;
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: var(--viewport-padding);
  gap: 10px;
  width: 390px;
  max-width: 100vw;
  margin: 0;
  list-style: none;
  z-index: 2147483647;
  outline: none;
}

.Toast_close__dXpfe {
  all: unset;
}

.Toast_toast__TyZts {
  background-color: white;
  border-radius: 6px;
  box-shadow:
    hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  padding: 15px;
  display: grid;
  grid-template-areas: "title action" "description action";
  grid-template-columns: auto max-content;
  grid-column-gap: 15px;
  column-gap: 15px;
  align-items: center;
}
.Toast_toast__TyZts[data-state="open"] {
  animation: Toast_slideIn__dvfIC 150ms cubic-bezier(0.16, 1, 0.3, 1);
}
.Toast_toast__TyZts[data-state="closed"] {
  animation: Toast_hide__Ahz2B 100ms ease-in;
}
.Toast_toast__TyZts[data-swipe="move"] {
  transform: translateX(var(--radix-toast-swipe-move-x));
}
.Toast_toast__TyZts[data-swipe="cancel"] {
  transform: translateX(0);
  transition: transform 200ms ease-out;
}
.Toast_toast__TyZts[data-swipe="end"] {
  animation: Toast_swipeOut___wZrB 100ms ease-out;
}

@keyframes Toast_hide__Ahz2B {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes Toast_slideIn__dvfIC {
  from {
    transform: translateX(calc(100% + var(--viewport-padding)));
  }
  to {
    transform: translateX(0);
  }
}

@keyframes Toast_swipeOut___wZrB {
  from {
    transform: translateX(var(--radix-toast-swipe-end-x));
  }
  to {
    transform: translateX(calc(100% + var(--viewport-padding)));
  }
}

.Toast_title__szkc2 {
  grid-area: title;
  margin-bottom: 5px;
  font-weight: 500;
  color: var(--slate-12);
  font-size: var(--size-font-lg);
}

.Toast_description__ueDJx {
  grid-area: description;
  margin: 0;
  color: var(--slate-11);
  font-size: 13px;
  line-height: 1.3;
}

.Toast_action__FldmJ {
  grid-area: action;
}

.Toast_close__dXpfe {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-border);
  border-radius: 4px;
  font-weight: 500;
  color: var(--color-active--trigger);
  background-color: var(--color-bg-active--trigger);
}

:root {
  /** brand color tokens */
  --color-primary--rgb-triplet: 39, 52, 139; /* Skanssi blue */
  --color-primary: rgb(var(--color-primary--rgb-triplet, 1));
  --color-secondary--rgb-triplet: 182, 206, 53; /* Skanssi green */
  --color-secondary: rgb(var(--color-secondary--rgb-triplet, 1));
  --color-brand--vanilla-500: rgb(255, 246, 234);
  --color-brand--blue-500: rgb(5, 73, 140);
  --color-brand--blue-100: rgb(188, 221, 253);
  --color-brand--turqoise-200: rgb(168, 218, 224);
  --color-brand--turqoise-100: rgb(233, 246, 247);
  --color-brand--honey-200: rgb(240, 213, 155);
  --color-extra--error: rgb(207, 45, 35);
  --color-extra--error-rgb-triplet: 207, 45, 35;
  --color-extra--attention: rgb(207, 45, 35);
  --color-extra--attention-rgb-triplet: 207, 45, 35;

  --color: rgb(2, 2, 2);
  --color-inactive: rgb(60, 60, 60);
  --color-placeholder: rgb(120, 120, 210);
  --color-bg: rgb(250, 250, 250);
  --color-border: #ced4da; /* rgb(37, 37, 37);*/
  --color-outline: rgb(37, 37, 37);
  --color-shadow: rgb(37, 37, 37);

  --color-text: var(--color);
  --color-text-heading: var(--color-brand--blue-500);

  --color-bg-button: var(--color-primary);
  --color-bg-button--hover: rgb(var(--color-primary--rgb-triplet), 0.9);
  --color-text-button: rgb(254, 253, 250);

  --color-input-label: var(--color-inactive);
  --color-input-placeholder: var(--color-placeholder);

  --color-active--trigger: var(--color);
  --color-inactive--trigger: var(--color-inactive);
  --color-bg-active--trigger: rgb(var(--color-secondary--rgb-triplet), 0.2);
  --color-bg-modal: rgb(var(--color-primary--rgb-triplet), 0.2);

  --color-bg-error: rgb(var(--color-extra--error-rgb-triplet), 0.2);
  --color-bg-warning: var(--color-brand--honey-200);
  --color-bg-success: var(--color-brand--turqoise-100);
  --color-bg-neutral: var(--color-brand--blue-100);

  /* base sizes */
  --size-base: 16px;
  --size-font-base: var(--size-base, 1rem);
  --size-font-sm: calc(var(--size-font-base) * 0.8);
  --size-font-md: var(--size-font-base);
  --size-font-lg: calc(var(--size-font-base) * 1.2);

  --space: 1rem;
  --space-xs: calc(var(--space) / 4);
  --space-sm: calc(var(--space) / 2);
  --space-md: calc(var(--space));
  --space-lg: calc(var(--space) * 2);
  --space-xl: calc(var(--space) * 4);

  --space-form-fieldgap: var(--space-md);

  --radius: 0.25rem;
}

.reset-button {
  all: unset;
  outline: revert;
  background-color: transparent;
}

.reset-ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

body {
  background-color: var(--color-bg);
}

